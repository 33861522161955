import React, { FC } from 'react';
import Search from './Search';


export interface Props {}

const SearchPage: FC<Props> = (props) => {
  return <Search />;
};

export default SearchPage;
