export enum RiotImageType {
  SPLASH = 'splash',
  CHAMPION = 'champion',
  LOADING = 'loading',
  PASSIVE = 'passive',
  SPELL = 'spell',
  ITEM = 'item',
  PROFILEICON = 'profileicon',
  MAP = 'map',
  SPRITE = 'sprite',
  UI = 'ui'
}
