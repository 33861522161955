import React, { FC } from 'react';
import ErrorBoundary from './ErrorBoundary';
import { useErrorMutation } from '../operations';
import { log } from '../utils';

const ErrorReporter: FC = ({ children }) => {
  const [reportError] = useErrorMutation();

  return (
    <ErrorBoundary
      onError={(errorState) => {
        log.error(errorState);
        reportError({
          variables: {
            error: JSON.stringify({
              error: JSON.stringify(errorState.error, [
                ...Object.getOwnPropertyNames(errorState.error),
              ]),
              info: errorState.info,
            }),
          },
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorReporter;
