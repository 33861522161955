import React, { FC } from 'react';
import './AnimatedSpinner.css';


const AnimatedSpinner: FC = (props) => {
  return (
    <div className="AnimatedSpinner">
      <div></div>
    </div>
  );
};

export default AnimatedSpinner;
